import React, {useContext} from 'react';
import { HashLink as Link } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
//import { slide as Menu } from 'react-burger-menu'
import BurgerMenu from 'react-burger-menu';
import {ContextApp} from './../../context';
import {onSideMenuOpen} from './actions'

const SideMenu = () => {
    const {state, dispatch} = useContext(ContextApp);
    const { sideMenuOpen } = state; 
    const location = useLocation()

    const Menu = BurgerMenu['slide'];

    const handleStateChange = (state) => {
        dispatch(onSideMenuOpen(state.isOpen))
    }

    const onLinkClick = () => {
        dispatch( onSideMenuOpen(false) )
    };

    return (
    <div className={'right'}>
        <Menu right={true} pageWrapId={ "general-wrapper"} outerContainerId={'app'} 
            isOpen={sideMenuOpen}
            onStateChange={(state) => handleStateChange(state)}
            >
            <div className="nav">
                <ul>
                    {location.pathname === '/' &&
                        <>
                            <li className="nav-item">
                                <Link
                                    to="#general-description"
                                    smooth
                                    className="nav-link"
                                    onClick={onLinkClick}
                                >
                                    Регулювання системи паркування
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#howParkingInspectionWorks"
                                    smooth
                                    className="nav-link"
                                    onClick={onLinkClick}
                                >
                                    Інспекція з паркування
                                </Link>
                            </li>
                        </>
                    }
                    {location.pathname.includes('/violation-information') &&
                        <li className="nav-item">
                            <Link
                                to="/"
                                smooth
                                className="nav-link"
                                onClick={onLinkClick}
                            >
                                Головна
                            </Link>
                        </li>
                    }
                    <li className="nav-item">
                        <Link to="#app-footer" 
                            smooth 
                            className="nav-link" 
                            onClick={onLinkClick}
                        >
                            Контакти
                        </Link>
                    </li>
                </ul>
            </div>
        </Menu>
    </div>
    );
}

export default SideMenu