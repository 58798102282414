import * as actionsType from './../actions/constants';

export const initialState = {
    isVisible: false,
    contactInformationVisible: false,
    progress: false,
    violationReport: undefined,
    violationExist: null,
    hasError:false,
    message: undefined,
    legislationVisible:false,
    readMore: false,
    statisticsDataProgress: false,
    statisticsData: undefined,
    settings: undefined,
    sideMenuOpen: false,
}

export const mainReducer = (state, action) => {
    switch(action.type){
        case actionsType.ON_SIDE_MENU_TOGGLE:
            return {
                ...state,
                sideMenuOpen: !state.sideMenuOpen
            }
        case actionsType.ON_SIDE_MENU_OPEN:
            return {
                ...state,
                sideMenuOpen: action.sideMenuOpen
            }
        case actionsType.SET_VIOLATION_REPORT:
            return {
                ...state,
                violationReport: action.violationReport,
                hasError: false,
                progress: false,
                //isVisible: true,
                message: undefined,
                violationExist: null,
            }
        case actionsType.SET_HAS_ERROR:
            return{
                ...state,
                violationReport: undefined,
                hasError: true,
                progress: false,
                message: action.message
            }
        case actionsType.CLEAR_ERROR:
            return{
                ...state,
                hasError: false,
                message: undefined
            }
        case actionsType.FETCH_VIOLATION_REPORT:
            return{
                ...state,
                progress: true,
            }
        case actionsType.CLOSE_POP_UP_GALLERY:
            return{
                ...state,
                isVisible: false
            }
        case actionsType.ON_EXIST_VIOLATION:
            return{
                ...state,
                violationExist: action.data,
                progress: false,
            }
        case actionsType.SHOW_POP_UP_GALLERY:{
            return{
                ...state,
                isVisible: true
            }
        }
        case actionsType.SHOW_DIALOG_CONTACT_INFORMATION:
            return{
                ...state,
                contactInformationVisible: true
            }
        case actionsType.MADE_DIALOG_CONTACT_INFORMATION:
            return{
                ...state,
                contactInformationVisible: false
            }
        case actionsType.SHOW_DIALOG_LEGISLATION:
            return{
                ...state,
                legislationVisible:true
            }
        case actionsType.MADE_DIALOG_LEGISLATION:
            return{
                ...state,
                legislationVisible:false
            }
        case actionsType.TOGGLE_READ_MORE:
            return{
                ...state,
                readMore: !state.readMore
            }
        case actionsType.FETCH_STATISTICS_DATA:
            return{
                ...state,
                statisticsDataProgress: true
            }
        case actionsType.SET_STATISTICS_DATA:
            return{
                ...state,
                statisticsData: action.statisticsData,
                statisticsDataProgress: false
            }
        case actionsType.GET_STATISTICS_DATA_ERROR:
            return{
                ...state,
                statisticsDataProgress: false
            }
        case actionsType.SET_SETTINGS:
            return {
                ...state,
                settings: action.settings
            }
        default:
            return state
    }
}
