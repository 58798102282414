export const FETCH_VIOLATION_REPORT          = 'FETCH_VIOLATION_REPORT';
export const SET_VIOLATION_REPORT            = 'SET_VIOLATION_REPORT';
export const SET_HAS_ERROR                   = 'SET_HAS_ERROR';
export const CLEAR_ERROR                     = 'CLEAR_ERROR';
export const CLOSE_POP_UP_GALLERY            = 'CLOSE_POP_UP_GALLERY';
export const SHOW_DIALOG_CONTACT_INFORMATION = 'SHOW_DIALOG_CONTACT_INFORMATION';
export const MADE_DIALOG_CONTACT_INFORMATION = 'MADE_DIALOG_CONTACT_INFORMATION';
export const SHOW_DIALOG_LEGISLATION         = 'SHOW_DIALOG_LEGISLATION';
export const MADE_DIALOG_LEGISLATION         = 'MADE_DIALOG_LEGISLATION';
export const TOGGLE_READ_MORE                = 'TOGGLE_READ_MORE';
export const SHOW_POP_UP_GALLERY             = 'SHOW_POP_UP_GALLERY';

export const FETCH_STATISTICS_DATA           = 'FETCH_STATISTICS_DATA';
export const SET_STATISTICS_DATA             = 'SET_STATISTICS_DATA';
export const GET_STATISTICS_DATA_ERROR       = 'GET_STATISTICS_DATA_ERROR';
export const SET_SETTINGS                    = 'SET_SETTINGS';
export const ON_EXIST_VIOLATION              = 'ON_EXIST_VIOLATION';
export const ON_SIDE_MENU_OPEN              = 'ON_SIDE_MENU_OPEN';
export const ON_SIDE_MENU_TOGGLE              = 'ON_SIDE_MENU_TOGGLE';
