import React, {useReducer, lazy, Suspense, useEffect} from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import {ContextApp} from './context';
import NotFound from './features/notfound';
import {mainReducer, initialState} from './features/main/reducers';
import ScrollUpButton from "react-scroll-up-button";
import {getStatisticsData} from "./features/main/actions";
import {WrapperHelmet} from "./features/main/components/WrapperHelmet"
import SideMenu from './features/main/SideMenu'

const ButtonUp = ()=> ( <div className='button-up' /> )

const Main = lazy(()=> import('./features/main'));
const ViolationInformation = lazy(()=> import('./features/violation-information'));
const Legislation = lazy(()=> import('./features/legislation'));
const OrderPayFine = lazy(()=> import('./features/order-pay-fine'));

const Loader = () => (<div></div>)

const App = () => {
    const [state, dispatch] = useReducer(mainReducer, initialState);

    useEffect(()=> {
        getStatisticsData()(dispatch);
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ContextApp.Provider value={{dispatch, state}}>
            <BrowserRouter>
                <div className='app'>
                    <SideMenu />
                    <WrapperHelmet />
                    <Suspense fallback={<Loader />}>
                        <Switch>
                            <Route exact path='/' component={Main} />
                            <Route exact path='/check-fine/:licensePlate' component={Main} />
                            <Route exact path='/main' component={Main} />
                            <Route exact path='/violation-information/:violationNumber/:licensePlate' component={ViolationInformation} />
                            <Route exact path='/violation-information/:source/:violationNumber/:licensePlate' component={ViolationInformation} />
                            <Route exact path='/law' component={Legislation} />
                            <Route exact path='/order-pay-fine' component={OrderPayFine} />
                            <Route path='*' component={NotFound} />
                        </Switch>
                        <ScrollUpButton EasingType="linear"
                                        ShowAtPosition={200}
                                        ContainerClassName="button-up-container"
                                        TransitionClassName="button-up-for-transition">
                            <ButtonUp />
                        </ScrollUpButton>
                    </Suspense>
                </div>
            </BrowserRouter>
        </ContextApp.Provider>
)};
export default App;

