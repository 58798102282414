import React from 'react';

const NotFound = () => (
    <main>
        <p>
            Page not found
        </p>
    </main>
);

export default NotFound;