import React from 'react';
import {Helmet} from 'react-helmet';


export const WrapperHelmet = () => {
    let keywords, title, description, author, placename, position, owner
    
    if (process.env.REACT_APP_CITY === 'kmr' ){
        title = 'Оплата паркування та перевірка штрафів в місті Коломия'
        keywords = "оплата паркування, перевірка штрафів, Коломия, автостоянка, онлайн сервіс"
        description = 'Оплачуйте паркування та перевіряйте штрафи в Коломиї онлайн. Зручний сервіс для автовласників.'
        author = 'Муніципальна інспекція міста Коломия'
        placename = 'Коломия'
        position = '48.5246062;25.0371737'
        owner = 'Муніципальна інспекція міста Коломия'
    }
    if (process.env.REACT_APP_CITY === 'cv' ){
        title = 'CarP - сервіс для координування парковок'
        keywords = "інспекція паркування, карп паркування, штраф, carp, інспектор, оплата паркування, перевірка штрафів, автостоянка, онлайн сервіс, Чернівці, парковка чернівці, паркування чернівці, Carp чернівці, карп, інспекція з паркування номер, оператор з паркування, оплата за парковку онлайн, додаток парковка, сплатити штраф онлайн, стоянка, парковка для інвалідів"
        description = 'Оплачуйте паркування та перевіряйте штрафи в Чернівцях онлайн. Зручний сервіс для автовласників.'
        author = 'Муніципальна інспекція міста Чернівці'
        placename = 'Чернівці'
        position = '48.29166311948197;25.93483863067256'
        owner = 'Муніципальна інспекція міста Чернівці'
    }
    return(
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <meta name="keywords" content={keywords} />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta http-equiv="content-language" content="uk"/>
                <meta name="author" content={author}/>
                <meta name="geo.placename" content={placename}/>
                <meta name="geo.position" content={position}/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="owner" content={owner}/>
                <meta name="robots" content="index, follow"></meta>
            </Helmet>
            
    )
}