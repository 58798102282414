import {fetchApi} from './../../../api';
import * as actionsType from './constants';

const violationInfoUrl = "enforcer/violation/getInfo";
const isViolationUrl = 'enforcer/violation/exist';
//const getStatisticsDataUrl = "violation/getStatisticsData";
const getInfo = 'enforcer/getInfo';

const setViolationReport = (violationReport)=>({
    type: actionsType.SET_VIOLATION_REPORT,
    violationReport
});

const setHasError = (message) =>({
    type: actionsType.SET_HAS_ERROR,
    message
})

export const onSideMenuOpen = (sideMenuOpen) =>({
    type: actionsType.ON_SIDE_MENU_OPEN,
    sideMenuOpen
})

export const onSideMenuToggle = () =>({
    type: actionsType.ON_SIDE_MENU_TOGGLE,
})

export const onClearError = () =>({
    type: actionsType.CLEAR_ERROR,
})

const fetchViolationReport = ()=> ({
    type: actionsType.FETCH_VIOLATION_REPORT
})

export const checkViolation = (violationNumber, licensePlate, source, history, hideCheckViolationModal)=>(dispatch) => {

    let params = `vlnCode=${violationNumber}&licensePlate=${licensePlate}${source ? '&source='+source : ''}`;

    dispatch(fetchViolationReport());

    let callbacks = {
        onSuccess: (response) => {
            dispatch(setViolationReport(response));
            if(history){
                history.push({
                    pathname: `/violation-information/${source? source + '/' : ''}${violationNumber}/${licensePlate}`,
                });
            }
        },
        onFailed: (response) => {
            //hideCheckViolationModal()
            dispatch(setHasError(response.message));
        },
        onError: (error) => {
            dispatch(setHasError(error.message));
        }
    };

    fetchApi(violationInfoUrl, params, callbacks);
}


export const isViolation = (licensePlate, source, history, showCheckViolationModal) => (dispatch) => {

    let params = `licensePlate=${licensePlate}${source ? '&source='+source : ''}`;

    dispatch(fetchViolationReport());

    let callbacks = {
        onSuccess: (response) => {
            dispatch(onExistViolation(response.exist));
            showCheckViolationModal()
        },
        onFailed: (response) => {
            dispatch(setHasError(response.message));
        },
        onError: (error) => {
            dispatch(setHasError(error.message));
        }
    };

    fetchApi(isViolationUrl, params, callbacks);
}

export const onExistViolation = (data)=> ({
    type: actionsType.ON_EXIST_VIOLATION,
    data
})

export const onClosePopUpGallery = ()=> ({
    type: actionsType.CLOSE_POP_UP_GALLERY
})

export const onShowPopUpGallery = ()=> ({
    type: actionsType.SHOW_POP_UP_GALLERY
})

export const onShowDialogContactInformation = ()=>({
    type: actionsType.SHOW_DIALOG_CONTACT_INFORMATION
})

export const onMadeDialogContactInformation = () => ({
    type: actionsType.MADE_DIALOG_CONTACT_INFORMATION
})

export const onShowDialogLegislation = ()=> ({
    type: actionsType.SHOW_DIALOG_LEGISLATION
})

export const onMadeDialogLegislation = ()=> ({
    type: actionsType.MADE_DIALOG_LEGISLATION
})

export const onToggleReadMore = ()=> ({
    type: actionsType.TOGGLE_READ_MORE
})

const fetchStatisticsData = () => ({
    type: actionsType.FETCH_STATISTICS_DATA
})

const setStatisTicsData = (statisticsData) =>({
    type: actionsType.SET_STATISTICS_DATA,
    statisticsData
})

const setSettings = (settings) => ({
    type: actionsType.SET_SETTINGS,
    settings
})


const getStatisticsDataError = ()=> ({
    type: actionsType.GET_STATISTICS_DATA_ERROR
})

export const getStatisticsData = ()=>(dispatch)=>{
    let params = ``;

    dispatch(fetchStatisticsData());
    let callbacks = {
        onSuccess: (response) => {
              /*let stat = {
                    "previous_year_payments": {
                        "money": 3955179.77,
                        "count": 15035
                    },
                    "last_year": {
                        "money": 531680,
                        "count": 1386
                    },
                    "previous_year": {
                        "money": 7777625,
                        "count": 18764
                    },
                    "current_year_payments": {
                        "money": 257361,
                        "count": 1073
                    },
                    "previous_month": {
                        "money": 960660,
                        "count": 2453
                    }
                }*/
            //setTimeout( () => {
            dispatch( setStatisTicsData(response.statisticsData) ); // stat //response.statisticsData
            dispatch( setSettings(response.settings) );
            //}, 4000)
        },
        onFailed: (response) => {
            dispatch(getStatisticsDataError());
        },
        onError: (error) => {
            dispatch(getStatisticsDataError());
        }
    };

    fetchApi(getInfo, params, callbacks);
}


